import styled from '@emotion/styled';
import { Alert, Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, Switch, TextField, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useAuth } from '../../../providers/AuthProvider';
import { useBook } from '../../../providers/BookProvider';
import books from '../../../shared/books';
import item from '../../../shared/item';
import _protected from '../../../shared/protected';
import AdminCatalog from '../../private/page/AdminCatalog';
import LazyImg from '../../public/LazyImg';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Chips from '../../public/Chips';
import LicItem from '../../public/Item';

function RoomSku(props) {
  console.log('rerender RoomSku');
  let auth = useAuth();
  let books = useBook();

  const [deleting, setDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const [taskId, setTaskId] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [linked, setLinked] = useState(null);

  const [selected, setSelected] = useState([]);

  const [formAlert, setFormAlert] = useState("");
  const [formError, setFormError] = useState({});

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({
    preview: {
      text: "",
      files: []
    },
    rights: [],
    ifta: []
  });

  const columns = [{
    field: 'ID',
    headerName: 'Номер (ID)',
    width: 50
  }, {
    field: 'actions',
    type: 'actions',
    headerName: 'Edit',
    width: 60,
    cellClassName: 'actions',
    getActions: ({ id }) => {
      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => handleEditClick(id)}
          color="inherit"
        />
      ];
    }
  }, {
    field: 'link',
    headerName: 'Ссылка на задачу',
    filterable: false
  }, {
    field: 'contract',
    headerName: 'Номер Договора',
    width: 400
  }, {
    field: 'status',
    headerName: 'Срок действия',
    width: 300
  }, {
    field: 'category',
    headerName: 'Категория',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.category} />;
    },
    valueGetter: (params) => {
      let { row } = params;
      if (row.category && Array.isArray(row.category)) {
        return row.category.join(',');
      }
      return row.category ?? "";
    }
  }, {
    field: 'title_project',
    headerName: 'Наименование проекта',
    width: 300
  }, {
    field: 'comment',
    headerName: 'Комментарий',
    width: 300
  }, {
    field: 'count_sku',
    headerName: 'Количество SKU в проекте'
  }, {
    field: 'agree_count_sku',
    headerName: 'Количество согласованных SKU'
  }, {
    field: 'articul',
    headerName: 'Артикул',
    width: 100
  }, {
    field: 'barcode',
    headerName: 'Штрихкод',
    width: 200
  }, {
    field: 'countryes',
    headerName: 'Страны Дистрибуции',
    width: 300
  }, {
    field: 'created',
    headerName: 'Создан',
    width: 300
  }, {
    field: 'trade_price',
    headerName: 'Оптовая цена',
  }, {
    field: 'retail_price',
    headerName: 'Розничная цена',
  }, {
    field: 'distribution',
    headerName: 'Каналы дистрибуции',
    width: 300
  }, {
    field: 'products_date',
    headerName: 'Дата производства',
    width: 300
  }, {
    field: 'sales_date',
    headerName: 'Дата начала продаж',
    width: 300
  }, {
    field: 'approval_date',
    headerName: 'Дата утверждение проекта',
  }];

  const handleEditClick = (id) => {
    let _form = rows.find(v => {
      return v._id == id;
    });
    if (!_form) {
      alert('У вас нет доступа к данному договору');
      return;
    }
    setForm(_form);
    setFormAlert("");
    setFormError({});
    setEdit(true);
    setOpen(true);
  }

  const handleClickOpen = () => {
    setFormAlert("");
    setFormError({});
    setOpen(true);
  };

  const aggregate = [];

  const loadSku = useCallback(() => _protected.load(auth.token, 'sku', aggregate), []);

  useEffect(() => {
    if (loading) {
      if (window.BX24) {
        let info = window.BX24.placement.info();
        switch (info.placement) {
          case "TASK_VIEW_TAB":
            aggregate.splice(0, 0, {
              '$match': {
                'taskId': info.options.taskId
              }
            });
            setTaskId(info.options.taskId);
            break;
        }

        window.BX24.callMethod('user.current', {}, (res) => {
          if (res.data()) {
              let user = res.data();
              setCurrentUser(user);
            }
        });
      }
      loadSku().then(v => {
        if (v.status === 'ok') {
          console.log(v.result);
          setRows(v.result);
          setLoading(false);
        }
      });
    }
  }, [loading]);

  useEffect(async () => {
    if (taskId && !linked) {
      let task = await b24Call('tasks.task.get', {
        taskId: taskId
      });
      let groupId = task.task.groupId;
      let result = await b24Call('crm.item.list', {
        "entityTypeId": 1032,
        "filter": {
            "0": { "ufCrm7_1730210846952": `%/${groupId}/%`}
        }
      });
      setLinked(result.items);
    }
  }, [taskId])

  const handleClose = () => {
    setForm({});
    setEdit(false);
    setOpen(false);
  };

  const handleClickDelete = () => {
    setDelete(true);
  }

  const handleDelete = async () => {
    for (let i = 0; i < selected.length; i++) {
      await _protected.delete(auth.token, 'international', selected[i]);
    }
    setLoading(true);
    setDelete(false);
  }

  const handleAdd = async () => {
    let data = {
      title_project: form.title_project,
      taskId: taskId ?? 0,
      comment: form.comment,
      sales_date: form.sales_date,
      approval_date: form.approval_date,
      trade_price: form.trade_price
    }
    _protected.add(auth.token, 'sku', data).then(v => {
      if (v.status === 'ok') {
        setLoading(true);
        handleClose();
      }
    });
  }

  const b24Call = ( method, data ) => {
    console.log(`b24 call ${method}`);
    return new Promise((resolve, reject) => {
      fetch(`https://bitrix.riki.team/rest/552/ue0pi15jnlbv7ww6/${method}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(response => {
        return response.json();
      }, (reason) => {
        reject(reason);
      }).then(async (result) => {
        resolve(result.result);
      })
    })
  }

  return [
    <Box component="div" sx={{ margin: '10px 5px' }}>
      <Button size='small' onClick={handleClickOpen} variant="contained">Добавить</Button>
      {selected.length > 0 && auth.bxMode != true && <Button size='small' onClick={handleClickDelete} variant="contained" color="error" sx={{ ml: 1 }}>Удалить</Button>}
    </Box>,
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        {useMemo(() => <DataGridPro
          getRowId={(row) => row._id}
          getRowHeight={() => 'auto'}
          showCellRightBorder
          //getDetailPanelHeight={({}) => 200}
          //getDetailPanelContent={getDetailPanelContent}
          rows={rows}
          columns={columns}
          onSelectionModelChange={(selection) => setSelected(selection)}
          selectionModel={selected}
          slots={{
            toolbar: GridToolbar
          }}
          pagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          initialState={{
            pagination: {
              pageSize: 25
            }
          }}
          checkboxSelection
          disableSelectionOnClick
          autoHeight
        />, [rows])}
      </div>
    </div>,
    <Dialog open={open} aria-labelledby="responsive-dialog-title" maxWidth="md" fullWidth sx={{ zIndex: 999 }}>
      <DialogTitle id="responsive-dialog-title">
        {!edit && "Добавление нового элемента."}
        {edit && "Редактирование элемента."}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2} paddingTop={1}>
          <Grid items xs={6}>
            <Autocomplete
              multiple
              freeSolo
              disablePortal
              defaultValue={form.contract ? form.contract : []}
              options={[]}
              renderInput={(params) => <TextField {...params} label="Номер Договора" />}
              // onInputChange={(e, v) => {
              //   setInputs({ ...inputs, contract: v });
              // }}
              onChange={(e, v) => {
                if (v) {
                  setForm({ ...form, contract: v });
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="title_project"
              label="Наименование проекта"
              type="text"
              defaultValue={form.title_project ? form.title_project : ""}
              onChange={(e) => {
                setForm({ ...form, title_project: e.target.value });
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="comment"
              label="Комментарий"
              type="text"
              defaultValue={form.comment ? form.comment : ""}
              onChange={(e) => {
                setForm({ ...form, comment: e.target.value });
              }}
              multiline
              rows={4}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="trade_price"
              label="Оптовая цена"
              type="number"
              defaultValue={form.trade_price ? form.trade_price : ""}
              onChange={(e) => {
                setForm({ ...form, trade_price: e.target.value })
              }}
              sx={{ width: '100%' }}
              slotProps={{
                input: {
                  startAdornment: <InputAdornment position="start">₽</InputAdornment>,
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="retail_price"
              label="Розничная цена"
              type="number"
              defaultValue={form.approval_date ? form.approval_date : ""}
              onChange={(e) => {
                setForm({ ...form, approval_date: e.target.value })
              }}
              sx={{ width: '100%' }}
              slotProps={{
                input: {
                  startAdornment: <InputAdornment position="start">₽</InputAdornment>,
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="sales_date"
              label="Дата начала продаж"
              type="date"
              defaultValue={form.sales_date ? form.sales_date : ""}
              onChange={(e) => {
                setForm({ ...form, sales_date: e.target.value });
              }}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="approval_date"
              label="Дата утверждение проекта"
              type="date"
              defaultValue={form.approval_date ? form.approval_date : ""}
              onChange={(e) => {
                setForm({ ...form, approval_date: e.target.value });
              }}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAdd} autoFocus>
          Добавить
        </Button>
        <Button onClick={handleClose} autoFocus>
          Отменить
        </Button>
      </DialogActions>
    </Dialog>,
    <Dialog open={deleting} aria-describedby="alert-dialog-slide-description">
      <DialogTitle>{"Удаление объектов."}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Подтвердите удаление.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDelete(false)}>Нет</Button>
        <Button onClick={() => handleDelete()}>Да</Button>
      </DialogActions>
    </Dialog>
  ];
}

export default RoomSku;
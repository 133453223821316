import { Box, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Route, Routes, useNavigate } from "react-router-dom";

import { BookProvider } from '../../providers/BookProvider';
import RoomSku  from './page/RoomSku';
import RoomInternational  from './page/RoomInternational';
import AdminCatalog from '../private/page/AdminCatalog';
import RoomShared from './page/RoomShared';

// import { lazy } from "react";
// const BookProvider  = lazy(() => import('../../providers/BookProvider'));
// const RoomSku  = lazy(() => import('./page/RoomSku'));
// const RoomInternational = lazy(() => import('./page/RoomInternational'));
// const AdminCatalog = lazy(() => import('../private/page/AdminCatalog'));
// const RoomShared = lazy(() => import('./page/RoomShared'));

function Room(props) {
    console.log('Render room');
    return (
        <div className="adminPage" style={{ display: "flex" }}>
            {/* <Box sx={{ minWidth: '200px' }} className="menu">
                <Header sx={{ width: 100, margin: 'auto' }} />
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("catalog", { replace: true })}>
                            <ListItemText primary="Заявки" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("catalog", { replace: true })}>
                            <ListItemText primary="Профиль" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box> */}
            <div className="content">
                <Routes>
                    <Route path="shared" element={<RoomShared />} />
                    <Route index path="inter" element={<BookProvider><RoomInternational /></BookProvider>} />
                    <Route path="sku" element={<BookProvider><RoomSku /></BookProvider>} />
                    <Route path="catalog" element={<AdminCatalog />} />
                </Routes>
            </div>
        </div>
    );
}

export default Room;